var _a;
import { MESSAGES } from 'types/messages';
import { ANALYTICS_TYPES } from './analytics-types';
export var ACTIONS_WHITELIST = (_a = {},
    _a[MESSAGES.MOVIE_ADD_RESPONSE] = ANALYTICS_TYPES.DOWNLOAD,
    _a[MESSAGES.COL_MOVIE_REMOVE_RESPONSE] = ANALYTICS_TYPES.COL_MOVIE_REMOVE,
    _a[MESSAGES.COL_MOVIE_ADD_RESPONSE] = ANALYTICS_TYPES.COL_MOVIE_ADD,
    _a[MESSAGES.COLLECTION_ADD_RESPONSE] = ANALYTICS_TYPES.COLLECTION_ADD,
    _a[MESSAGES.COLLECTION_REMOVE_RESPONSE] = ANALYTICS_TYPES.COLLECTION_REMOVE,
    _a[MESSAGES.VIDEO_PLAY_START_RESPONSE] = ANALYTICS_TYPES.VIDEO_PLAY,
    _a[MESSAGES.VIDEO_PAUSE_RESPONSE] = ANALYTICS_TYPES.VIDEO_PAUSE,
    _a[MESSAGES.VIDEO_SEEK_RESPONSE] = ANALYTICS_TYPES.VIDEO_SEEK,
    _a[MESSAGES.VIDEO_PLAY_RESUME_RESPONSE] = ANALYTICS_TYPES.VIDEO_RESUME,
    _a[MESSAGES.VIDEO_ERROR_RESPONSE] = ANALYTICS_TYPES.VIDEO_CRASH,
    _a[MESSAGES.SHOW_RESPONSE] = ANALYTICS_TYPES.OPEN,
    _a[MESSAGES.HIDE_RESPONSE] = ANALYTICS_TYPES.HIDE,
    _a[MESSAGES.ANALYTICS_RESPONSE] = ANALYTICS_TYPES.PUSH,
    _a[MESSAGES.LANGUAGE_RESPONSE] = ANALYTICS_TYPES.LANGUAGE_CHANGE,
    _a[MESSAGES.THEME_RESPONSE] = ANALYTICS_TYPES.THEME_CHANGE,
    _a[MESSAGES.SORT_BY_CHANGE_RESPONSE] = ANALYTICS_TYPES.SORT_CHANGE,
    _a[MESSAGES.COLUMNS_CHANGE_RESPONSE] = ANALYTICS_TYPES.VIEW_CHANGE,
    _a[MESSAGES.FULLSCREEN_CHANGE_RESPONSE] = ANALYTICS_TYPES.FULLSCREEN,
    _a[MESSAGES.HANDSHAKE] = ANALYTICS_TYPES.INIT,
    _a[MESSAGES.STORIES_PLAY_RESPONSE] = ANALYTICS_TYPES.STORIES_PLAY,
    _a[MESSAGES.NOTIFICATION_OPEN_RESPONSE] = ANALYTICS_TYPES.NOTIFICATION_OPEN,
    _a[MESSAGES.NOTIFICATION_SKIP_RESPONSE] = ANALYTICS_TYPES.NOTIFICATION_SKIP,
    _a[MESSAGES.STORIES_STOP_RESPONSE] = ANALYTICS_TYPES.STORIES_STOP,
    _a[MESSAGES.STORIES_NEXT_RESPONSE] = ANALYTICS_TYPES.STORIES_NEXT,
    _a[MESSAGES.STORIES_PREV_RESPONSE] = ANALYTICS_TYPES.STORIES_PREV,
    _a[MESSAGES.STORIES_NEXT_AUTO_RESPONSE] = ANALYTICS_TYPES.STORIES_NEXT_AUTO,
    _a[MESSAGES.STORIES_MOVIE_CLICK_RESPONSE] = ANALYTICS_TYPES.STORIES_MOVIE_CLICK,
    _a[MESSAGES.STORIES_BUTTON_CLICK_RESPONSE] = ANALYTICS_TYPES.STORIES_BUTTON_CLICK,
    _a);

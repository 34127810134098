var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MESSAGES } from 'types/messages';
import { CLASS_NAMES } from '../App/styles';
import Button from '../Button';
var ButtonLauncher = /** @class */ (function (_super) {
    __extends(ButtonLauncher, _super);
    function ButtonLauncher() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.className = CLASS_NAMES.BUTTON_LAUNCHER;
        return _this;
    }
    ButtonLauncher.prototype.onClick = function () {
        var _this = this;
        // Timer is required to escape passing a click forward to a real element.
        setTimeout(function () {
            _this.props.services.broker.show();
        }, 50);
    };
    ButtonLauncher.prototype.update = function (type) {
        switch (type) {
            case MESSAGES.CALL:
            case MESSAGES.MOVIE_ACTIVE_INFO:
                break;
            case MESSAGES.SHOW_RESPONSE:
            case MESSAGES.LOADING:
            case MESSAGES.OVER:
                this.setState({ isVisible: false });
                break;
            case MESSAGES.HANDSHAKE:
            case MESSAGES.HIDE_RESPONSE:
            case MESSAGES.OUT:
                this.setState({ isVisible: true });
                this.pointerTimestamp = 0;
                break;
            default:
                break;
        }
    };
    return ButtonLauncher;
}(Button));
export default ButtonLauncher;

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { MESSAGES, } from 'types/messages';
import WidgetEvent from './WidgetEvent';
export var PUBLIC_READY_TYPE = 'ready';
export var TIMEOUT_IN_MS = 60000;
var EventService = /** @class */ (function () {
    function EventService(events) {
        this.events = [];
        this.origin = '';
        this.connected = false;
        this.registerEvents(events);
    }
    EventService.prototype.createHandshake = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var timerId = setTimeout(function () {
                reject(new Error("V_RACK_ERR_CONNECTION_TIMED_OUT: ".concat(TIMEOUT_IN_MS, "ms.")));
            }, TIMEOUT_IN_MS);
            _this.onCall = function (event) {
                var origin = event.origin, data = event.data;
                if (!_this.origin.includes(origin)) {
                    return;
                }
                if (data.type === MESSAGES.CALL) {
                    clearTimeout(timerId);
                    _this.onReply();
                    resolve();
                }
            };
            window.addEventListener('message', _this.onCall);
        }).finally(function () {
            window.removeEventListener('message', _this.onCall);
            _this.onCall = undefined;
        });
    };
    EventService.prototype.onReply = function () {
        var _this = this;
        this.onMessage = function (_a) {
            var data = _a.data, origin = _a.origin;
            var event = (data === null || data === void 0 ? void 0 : data.type) && _this.getEventByType(data === null || data === void 0 ? void 0 : data.type);
            var isReadyEvent = event === null || event === void 0 ? void 0 : event.isType(PUBLIC_READY_TYPE);
            if (!_this.origin.includes(origin) || !event) {
                return;
            }
            if (event.hasListeners) {
                event.dispatch(data);
            }
            else if (!isReadyEvent) {
                event.enqueue(data);
            }
            if (isReadyEvent) {
                _this.connected = true;
            }
        };
        window.addEventListener('message', this.onMessage);
        this.emit(MESSAGES.REPLY, {
            origin: window.location.origin,
        });
    };
    EventService.prototype.connect = function (context) {
        if (!(this.handshake instanceof Promise)) {
            this.target = context.target;
            this.origin = context.origin;
            this.handshake = this.createHandshake();
        }
    };
    EventService.prototype.emitAndWait = function (type, payload) {
        var _this = this;
        return this.handshake.then(function () {
            var _a;
            var href = window.location.href;
            var event = _this.getEventByType(type);
            if (!((_a = event === null || event === void 0 ? void 0 : event.permissions) === null || _a === void 0 ? void 0 : _a.canTriggerPromise)) {
                return Promise.reject(new Error("The event doesn't have permission to trigger a promise based request."));
            }
            var _b = __read(event.request(), 2), requestId = _b[0], promise = _b[1];
            _this.target.postMessage({ type: type, payload: payload, href: href, requestId: requestId }, _this.origin);
            return promise;
        });
    };
    EventService.prototype.emit = function (type, payload) {
        var _this = this;
        this.handshake.then(function () {
            var href = window.location.href;
            _this.target.postMessage({ type: type, payload: payload, href: href }, _this.origin);
        });
    };
    EventService.prototype.on = function (type, listener) {
        var event = this.getEventByType(type);
        var isReadyEvent = event === null || event === void 0 ? void 0 : event.isType(PUBLIC_READY_TYPE);
        var canSubscribe = !isReadyEvent || !this.connected;
        if (!event) {
            console.error("Unrecognized event type. Can't subscribe listener to the event - ".concat(type, "."));
            return function () { };
        }
        if (canSubscribe) {
            event.subscribe(listener);
        }
        if (!isReadyEvent) {
            event.processQueue();
        }
        else if (this.connected) {
            listener({});
        }
        return function () {
            event.unsubscribe(listener);
        };
    };
    EventService.prototype.getEventByType = function (type) {
        return this.events.find(function (event) { return event.isType(type); });
    };
    EventService.prototype.registerEvent = function (eventToRegister) {
        var _this = this;
        var event = new WidgetEvent(eventToRegister);
        this.events.push(event);
        return function () {
            _this.events = _this.events.filter(function (e) { return e !== event; });
        };
    };
    EventService.prototype.registerEvents = function (events) {
        var _this = this;
        events.forEach(function (event) { return _this.registerEvent(event); });
        return function () {
            _this.events = [];
        };
    };
    EventService.prototype.dispose = function () {
        var event = this.getEventByType('shutdown');
        if (this.handshake instanceof Promise) {
            this.origin = '';
            this.target = undefined;
            this.handshake = undefined;
            this.connected = false;
        }
        event.dispatch({ payload: {}, type: 'shutdown' });
        window.removeEventListener('message', this.onMessage);
    };
    return EventService;
}());
export default EventService;

var mapPropToKey = function (prop, separator, union) {
    if (separator === void 0) { separator = '-'; }
    if (union === void 0) { union = '_'; }
    if (typeof prop !== 'string') {
        throw new TypeError("Expected prop to be a string. Received ".concat(typeof prop));
    }
    return prop
        .split(separator)
        .map(function (value) { return value.toUpperCase(); })
        .join(union);
};
export var createMessage = function (prop, prefix, hasResponseType) {
    var _a, _b, _c;
    if (prefix === void 0) { prefix = 'vrack'; }
    if (hasResponseType === void 0) { hasResponseType = true; }
    var key = mapPropToKey(prop);
    var message = (_a = {},
        _a[key] = prop,
        _a);
    if (hasResponseType) {
        var responseKey = "".concat(key, "_RESPONSE");
        return Object.assign(message, (_b = {},
            _b[responseKey] = "".concat(prefix, ":").concat(prop, "-response"),
            _b));
    }
    return _c = {},
        _c[key] = prop,
        _c;
};
var createMessages = function (prefix) {
    var props = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        props[_i - 1] = arguments[_i];
    }
    return props.reduce(function (acc, prop) {
        var isObject = typeof prop === 'object';
        var hasValueAndResponse = isObject && 'value' in prop && 'hasResponseType' in prop;
        if (hasValueAndResponse) {
            return Object.assign(acc, createMessage(prop.value, prefix, prop.hasResponseType));
        }
        return Object.assign(acc, createMessage(prop, prefix));
    }, {});
};
export default createMessages;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { h, Component } from 'preact';
import { MESSAGES } from 'types/messages';
import { CLASS_NAMES } from '../App/styles';
var Blackout = /** @class */ (function (_super) {
    __extends(Blackout, _super);
    function Blackout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isVisible: false,
        };
        return _this;
    }
    Blackout.prototype.componentDidMount = function () {
        this.props.services.observer.attach(this);
    };
    Blackout.prototype.componentWillUnmount = function () {
        this.props.services.observer.detach(this);
    };
    Blackout.prototype.update = function (type) {
        switch (type) {
            case MESSAGES.HIDE_RESPONSE:
                this.setState({ isVisible: false });
                break;
            case MESSAGES.SHOW_RESPONSE:
            case MESSAGES.NOTIFICATION_OPEN_RESPONSE:
                this.setState({ isVisible: true });
                break;
            default:
                break;
        }
    };
    Blackout.prototype.render = function () {
        return h('div', {
            className: CLASS_NAMES.BLACKOUT,
            style: {
                opacity: this.state.isVisible ? '1' : '0',
            },
        });
    };
    return Blackout;
}(Component));
export default Blackout;

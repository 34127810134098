export var sanitizeRecord = function (blacklist) {
    var blacklistMap = Object.values(blacklist).reduce(function (acc, cur) {
        var _a;
        return Object.assign(acc, (_a = {}, _a[cur] = cur, _a));
    }, {});
    var searchAndReplace = function (obj, initialObj) {
        if (initialObj === void 0) { initialObj = {}; }
        if (!Array.isArray(obj) || obj === null || typeof obj !== 'object') {
            return obj;
        }
        return Object.keys(obj).reduce(function (acc, key) {
            var _a, _b;
            var prop = obj[key];
            if (blacklistMap[key]) {
                return acc;
            }
            if (typeof prop === 'object') {
                return Object.assign(acc, (_a = {},
                    _a[key] = searchAndReplace(prop, Array.isArray(prop) ? [] : {}),
                    _a));
            }
            return Object.assign(acc, (_b = {}, _b[key] = prop, _b));
        }, initialObj);
    };
    return searchAndReplace;
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export var RECEIVER_SRC = process.env.REACT_APP_IFRAME_SRC;
var Postmate = /** @class */ (function () {
    function Postmate(observer) {
        Object.defineProperty(this, 'observer', {
            writable: false,
            configurable: false,
            enumerable: false,
            value: observer,
        });
    }
    Postmate.prototype.onMessage = function (event) {
        var _a = event || {}, _b = _a.origin, origin = _b === void 0 ? '' : _b, data = _a.data;
        var type = data.type, dataWithoutType = __rest(data, ["type"]);
        var isReceiverOrigin = RECEIVER_SRC.indexOf(origin) > -1;
        var isRootOrigin = origin === window.location.origin;
        if (typeof type !== 'string') {
            return;
        }
        if (isReceiverOrigin || isRootOrigin) {
            this.observer.notify(type, dataWithoutType);
        }
    };
    Postmate.prototype.listen = function () {
        window.addEventListener('message', this.onMessage.bind(this));
    };
    Postmate.prototype.dispose = function () {
        window.removeEventListener('message', this.onMessage.bind(this));
    };
    return Postmate;
}());
export default Postmate;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MESSAGES } from 'types/messages';
import { sanitizeRecord } from 'utils/sanitizer';
import compose from 'utils/compose';
var FUTURE_DOMAIN_NAME = 'vrack.me/';
var GTMEventService = /** @class */ (function () {
    function GTMEventService(broker) {
        this.broker = broker;
    }
    GTMEventService.prototype.sendAppEvent = function (action, payload) {
        this.broker.emit(MESSAGES.ANALYTICS, __assign({ action: action }, (payload || null)));
    };
    // eslint-disable-next-line class-methods-use-this
    GTMEventService.prototype.sendEvent = function (event) {
        var _a, _b;
        var sanitizedEvent = GTMEventService.handleProps(event);
        var toPush = __assign(__assign({}, sanitizedEvent), { event: 'vrack' });
        (_a = window === null || window === void 0 ? void 0 : window.vrackLayer) === null || _a === void 0 ? void 0 : _a.push(toPush);
        (_b = window === null || window === void 0 ? void 0 : window.dataLayer) === null || _b === void 0 ? void 0 : _b.push(toPush);
    };
    GTMEventService.removePrivateProps = sanitizeRecord(['token']);
    GTMEventService.mapDomainTo = function (domain) { return function (data) {
        return Object.keys(data).reduce(function (acc, key) {
            var _a, _b;
            if (typeof data[key] === 'string' && data[key].indexOf(domain) > -1) {
                return Object.assign(acc, (_a = {},
                    _a[key] = data[key].replace(domain, FUTURE_DOMAIN_NAME),
                    _a));
            }
            return Object.assign(acc, (_b = {},
                _b[key] = data[key],
                _b));
        }, {});
    }; };
    GTMEventService.handleProps = compose(GTMEventService.removePrivateProps, GTMEventService.mapDomainTo(process.env.REACT_APP_IFRAME_SRC));
    return GTMEventService;
}());
export default GTMEventService;

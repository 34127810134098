var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable no-underscore-dangle */
import { RESPONSE_STATUSES, } from 'types/messages';
import { nanoid } from 'nanoid';
var TIMEOUT_IN_MS = 30000;
var MAX_QUEUE_EVENTS = 5;
var WidgetEvent = /** @class */ (function () {
    function WidgetEvent(event) {
        this.permissions = {
            canTriggerPromise: false,
            canSubscribe: true,
        };
        this.listeners = [];
        this.queue = [];
        this.parseType(event.type);
        this.parsePermissions(event.permissions);
    }
    WidgetEvent.prototype.parseType = function (type) {
        if (typeof type === 'string') {
            this._type = type;
            this.type = type;
            return;
        }
        if (typeof type === 'object') {
            var _a = __read(Object.entries(type)[0] || [], 2), privateKey = _a[0], publicKey = _a[1];
            this._type = privateKey;
            this.type = publicKey;
            return;
        }
        throw new TypeError("Expected 'type' argument to be a string or an object.");
    };
    WidgetEvent.prototype.parsePermissions = function (permissions) {
        var _this = this;
        Object.keys(this.permissions).forEach(function (permission) {
            if (typeof (permissions === null || permissions === void 0 ? void 0 : permissions[permission]) === 'boolean') {
                _this.permissions[permission] = permissions[permission];
            }
        });
    };
    WidgetEvent.prototype.dispatch = function (data) {
        this.listeners.forEach(function (listener) {
            listener(data.payload);
        });
    };
    WidgetEvent.prototype.enqueue = function (data) {
        var payload = (data || {}).payload;
        if (typeof payload !== 'object') {
            throw new TypeError("Expected 'payload' to be an object.");
        }
        if (this.queue.length < MAX_QUEUE_EVENTS) {
            this.queue.push(payload);
        }
        else {
            this.queue = this.queue
                .filter(function (event, index) { return index !== 0; })
                .concat(payload);
        }
    };
    WidgetEvent.prototype.processQueue = function () {
        var _a = this, queue = _a.queue, listeners = _a.listeners;
        var _loop_1 = function () {
            var event_1 = queue.shift();
            listeners.forEach(function (listener) { return listener(event_1); });
        };
        while (queue.length > 0) {
            _loop_1();
        }
    };
    WidgetEvent.prototype.subscribe = function (listener) {
        if (typeof listener !== 'function') {
            throw new TypeError("Expected 'listener' to be a function. Received ".concat(typeof listener, "."));
        }
        if (!this.permissions.canSubscribe) {
            throw new Error("The event '".concat(this.type, "' does not permit listeners to subscribe."));
        }
        this.listeners.push(listener);
    };
    WidgetEvent.prototype.unsubscribe = function (listener) {
        if (typeof listener !== 'function') {
            throw new TypeError("Expected 'listener' to be a function. Received ".concat(typeof listener));
        }
        this.listeners = this.listeners.filter(function (fn) { return fn !== listener; });
    };
    WidgetEvent.prototype.request = function () {
        var _this = this;
        var uuid = nanoid();
        var onRequest = new Promise(function (resolve, reject) {
            var timerId = setTimeout(function () {
                reject(new Error("V_RACK_MESSAGE_EVENT_TIMED_OUT: ".concat(TIMEOUT_IN_MS, "ms. [").concat(_this.type, "]")));
            }, 30000);
            var onMessage = function (event) {
                var _a = event.data, requestId = _a.requestId, payload = _a.payload;
                if (!requestId || requestId !== uuid) {
                    return;
                }
                if (payload.status === RESPONSE_STATUSES.ERROR) {
                    reject(payload);
                }
                else {
                    resolve(payload);
                }
                clearTimeout(timerId);
                window.removeEventListener('message', onMessage);
            };
            window.addEventListener('message', onMessage);
        });
        return [uuid, onRequest];
    };
    WidgetEvent.prototype.isType = function (type) {
        if (!type) {
            return false;
        }
        return this._type.indexOf(type) > -1 || this.type.indexOf(type) > -1;
    };
    Object.defineProperty(WidgetEvent.prototype, "hasListeners", {
        get: function () {
            return this.listeners.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    return WidgetEvent;
}());
export default WidgetEvent;
/* eslint-enable no-underscore-dangle */

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Component, h } from 'preact';
import { MESSAGES } from 'types/messages';
import { ANALYTICS_TYPES, GTM_PARAMETERS } from '../../constants';
import { CLASS_NAMES } from '../App/styles';
import { get } from '../../utils/cookie';
var IFRAME_SRC = "".concat(process.env.REACT_APP_IFRAME_SRC).concat(process.env.REACT_APP_BASENAME);
/* eslint-disable no-underscore-dangle */
var Iframe = /** @class */ (function (_super) {
    __extends(Iframe, _super);
    function Iframe() {
        var _this = this;
        var _a;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.state = {
            isVisible: false,
            isFocused: false,
        };
        _this._src = (_a = _this.props.src) !== null && _a !== void 0 ? _a : IFRAME_SRC;
        _this._dataset = null;
        return _this;
    }
    Iframe.prototype.componentDidMount = function () {
        this.props.services.observer.attach(this);
    };
    Iframe.prototype.componentWillUnmount = function () {
        this.props.services.observer.detach(this);
    };
    Object.defineProperty(Iframe.prototype, "site", {
        // eslint-disable-next-line class-methods-use-this
        get: function () {
            var host = window.location.host;
            return host.split('.')[1];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Iframe.prototype, "dataset", {
        get: function () {
            var store = this.props.store;
            if (!this._dataset) {
                this._dataset = __assign({ site: this.site || 'heydouga', theme: 'dark', NetiA: get('NetiA'), NetiI: get('NetiI') }, store.dataset);
            }
            return this._dataset;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Iframe.prototype, "src", {
        get: function () {
            var query = Object.entries(this.dataset)
                .map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return "".concat(key, "=").concat(value);
            })
                .join('&');
            return "".concat(this._src, "#").concat(encodeURIComponent(query));
        },
        enumerable: false,
        configurable: true
    });
    Iframe.prototype.update = function (type) {
        switch (type) {
            case MESSAGES.HIDE_RESPONSE:
                this.setState({ isVisible: false, isFocused: false });
                break;
            case MESSAGES.SHOW_RESPONSE:
            case MESSAGES.NOTIFICATION_OPEN_RESPONSE:
                this.setState({ isVisible: true });
                break;
            case MESSAGES.LOADING:
            case MESSAGES.OUT:
                this.setState({ isFocused: false });
                break;
            case MESSAGES.OVER:
                this.setState({ isFocused: true });
                break;
            default:
                this.render();
                break;
        }
    };
    Iframe.prototype.onLoad = function (event) {
        var services = this.props.services;
        var target = event.target;
        if (target instanceof HTMLIFrameElement && target.contentWindow !== null) {
            var handshake = services.broker.setContext({
                target: target.contentWindow,
                origin: this._src,
            });
            handshake
                .then(function () {
                services.gtm.sendAppEvent(ANALYTICS_TYPES.INIT);
            })
                .catch(function () {
                var _a;
                services.gtm.sendEvent((_a = {
                        action: ANALYTICS_TYPES.CRASH
                    },
                    _a[GTM_PARAMETERS.VRACK_LOCATION] = window.location.href,
                    _a));
            });
        }
    };
    Iframe.prototype.render = function () {
        var _a = this.props.store, scrollbarWidth = _a.scrollbarWidth, isDisabled = _a.isDisabled;
        var _b = this.state, isVisible = _b.isVisible, isFocused = _b.isFocused;
        var canFocus = Boolean(isFocused && !isDisabled);
        var canClick = Boolean(isVisible && !isDisabled);
        return h('iframe', {
            src: this.src,
            className: CLASS_NAMES.IFRAME,
            allowFullscreen: true,
            onLoad: this.onLoad.bind(this),
            style: {
                'pointer-events': canClick || canFocus ? 'all' : 'none',
                'padding-right': isVisible ? scrollbarWidth : null,
                'max-height': isVisible ? '100%' : null,
            },
        });
    };
    return Iframe;
}(Component));
/* eslint-enable no-underscore-dangle */
export default Iframe;

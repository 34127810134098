var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Component } from 'preact';
import { ANALYTICS_TYPES, GTM_PARAMETERS, ACTIONS_WHITELIST, } from '../../constants';
import { GTM_CODE_SNIPPET } from '../../utils/get-gtm-snippet';
var Analytics = /** @class */ (function (_super) {
    __extends(Analytics, _super);
    function Analytics() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Analytics.prototype.componentDidMount = function () {
        this.props.services.observer.attach(this);
        document.head.appendChild(Analytics.SNIPPET);
        window.vrackLayer = (window.vrackLayer || []);
    };
    Analytics.prototype.componentWillUnmount = function () {
        this.props.services.observer.detach(this);
        document.head.removeChild(Analytics.SNIPPET);
    };
    Analytics.prototype.update = function (type, data) {
        var _a;
        var services = this.props.services;
        var gtm = services.gtm;
        var action = ACTIONS_WHITELIST[type];
        if (!action) {
            return;
        }
        switch (action) {
            case ANALYTICS_TYPES.PUSH:
                gtm.sendEvent(__assign(__assign({}, data === null || data === void 0 ? void 0 : data.payload), (_a = {}, _a[GTM_PARAMETERS.VRACK_LOCATION] = data === null || data === void 0 ? void 0 : data.href, _a)));
                break;
            default:
                gtm.sendAppEvent(action, data === null || data === void 0 ? void 0 : data.payload);
                break;
        }
    };
    Analytics.prototype.render = function () {
        return null;
    };
    Analytics.SNIPPET = (function () {
        var script = document.createElement('script');
        script.async = true;
        script.innerHTML = GTM_CODE_SNIPPET;
        return script;
    })();
    return Analytics;
}(Component));
export default Analytics;

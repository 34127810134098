var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable no-underscore-dangle, class-methods-use-this, no-undef */
import { MESSAGES } from 'types/messages';
import { CLASS_NAMES } from '../App/styles';
import Button from '../Button';
var ButtonNotifier = /** @class */ (function (_super) {
    __extends(ButtonNotifier, _super);
    function ButtonNotifier() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isVisible: false,
            shouldNotify: false,
            timerId: null,
        };
        _this.className = CLASS_NAMES.NOTIFY_BUTTON;
        return _this;
    }
    ButtonNotifier.prototype.onClick = function () {
        this.props.services.broker.show();
        this.setState({ shouldNotify: false, isVisible: false });
    };
    ButtonNotifier.prototype.componentDidMount = function () {
        this.props.services.observer.attach(this);
    };
    ButtonNotifier.prototype.componentWillUnmount = function () {
        clearTimeout(this.state.timerId);
        this.setState({ timerId: null });
        this.props.services.observer.detach(this);
    };
    ButtonNotifier.prototype.update = function (type, data) {
        var _this = this;
        switch (type) {
            case MESSAGES.HANDSHAKE:
                clearTimeout(this.state.timerId);
                this.setState({
                    timerId: setTimeout(function () {
                        var shouldNotify = data.payload.shouldNotify;
                        _this.setState({ isVisible: shouldNotify, shouldNotify: shouldNotify });
                    }, 3000),
                });
                break;
            case MESSAGES.OVER:
            case MESSAGES.LOADED:
            case MESSAGES.LOADING:
            case MESSAGES.SHOW_RESPONSE:
            case MESSAGES.NOTIFICATION_OPEN_RESPONSE:
                this.setState({ isVisible: false });
                break;
            case MESSAGES.OUT:
                this.setState({ isVisible: this.state.shouldNotify });
                this.pointerTimestamp = 0;
                break;
            default:
                break;
        }
    };
    return ButtonNotifier;
}(Button));
export default ButtonNotifier;
/* eslint-enable no-underscore-dangle, class-methods-use-this, no-undef */

export var ANALYTICS_TYPES = {
    COL_MOVIE_ADD: 'Add To Collection',
    COL_MOVIE_REMOVE: 'Remove From Collection',
    COLLECTION_ADD: 'Create Collection',
    COLLECTION_REMOVE: 'Remove Collection',
    DOWNLOAD: 'Download',
    VIDEO_PLAY: 'Play Movie',
    VIDEO_RESUME: 'Resume Movie',
    VIDEO_PAUSE: 'Pause Movie',
    VIDEO_SEEK: 'Seek Movie',
    VIDEO_CRASH: 'Movie Player Failed',
    SORT_CHANGE: 'Change Sort Type',
    VIEW_CHANGE: 'Change View Type',
    NOTIFICATION_OPEN: 'Open Notification',
    NOTIFICATION_SKIP: 'Skip Notification',
    LANGUAGE_CHANGE: 'Change Language Type',
    FULLSCREEN: 'Change Window Size',
    THEME_CHANGE: 'Change Theme Color',
    OPEN: 'Open V-Rack',
    HIDE: 'Close V-Rack',
    CRASH: 'Crash',
    PUSH: 'Insert',
    INIT: 'Launch',
    STORIES_PLAY: 'Play V-Pulse',
    STORIES_NEXT: 'Next story manually',
    STORIES_PREV: 'Prev story manually',
    STORIES_NEXT_AUTO: 'Next story auto',
    STORIES_STOP: 'Stop V-Pulse',
    STORIES_MOVIE_CLICK: 'Click movie card',
    STORIES_BUTTON_CLICK: 'Click primary button',
};

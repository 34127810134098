import Postmate from './Postmate';
import ObserverService from './ObserverService';
import WidgetEventBroker from './WidgetEventBroker';
import EventService from './EventService';
import GTMEventService from './GTMEventService';
var Services = /** @class */ (function () {
    function Services(events) {
        var eventService = new EventService(events);
        this.observer = new ObserverService();
        this.postmate = new Postmate(this.observer);
        this.broker = new WidgetEventBroker(eventService);
        this.gtm = new GTMEventService(this.broker);
    }
    return Services;
}());
export default Services;

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var isObject = function (obj) {
    return obj && typeof obj === 'object' && !Array.isArray(obj);
};
var deepMerge = function (objA, objB) {
    if (!isObject(objA) && !isObject(objB)) {
        return objB !== null && objB !== void 0 ? objB : objA;
    }
    var keysA = Object.keys(objA);
    var keysB = Object.keys(objB);
    var keys = Array.from(new Set(__spreadArray(__spreadArray([], __read(keysA), false), __read(keysB), false)));
    return keys.reduce(function (acc, key) {
        var _a, _b;
        var _c;
        var isAObject = isObject(objA[key]);
        var isBObject = isObject(objB[key]);
        if (isAObject || isBObject) {
            return Object.assign(acc, (_a = {},
                _a[key] = deepMerge(objA[key], objB[key]),
                _a));
        }
        return Object.assign(acc, (_b = {},
            _b[key] = (_c = objB[key]) !== null && _c !== void 0 ? _c : objA[key],
            _b));
    }, {});
};
export default deepMerge;

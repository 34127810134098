var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MESSAGES } from 'types/messages';
import BaseWidgetEventBroker from './BaseWidgetEventBroker';
var WidgetEventBroker = /** @class */ (function (_super) {
    __extends(WidgetEventBroker, _super);
    function WidgetEventBroker() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WidgetEventBroker.prototype.search = function (payload) {
        return this.emitAndWait(MESSAGES.MOVIES_SEARCH, payload);
    };
    WidgetEventBroker.prototype.show = function (id, referrer) {
        this.emit(MESSAGES.SHOW, { id: id, referrer: referrer });
    };
    WidgetEventBroker.prototype.hide = function () {
        this.emit(MESSAGES.HIDE);
    };
    WidgetEventBroker.prototype.loadMovie = function (payload) {
        return this.addMovie(payload, true);
    };
    WidgetEventBroker.prototype.addMovie = function (payload, animate) {
        var type = animate ? MESSAGES.LOAD : MESSAGES.MOVIE_ADD;
        return this.emit(type, payload);
    };
    WidgetEventBroker.prototype.getMovieInfo = function (id) {
        return this.emit(MESSAGES.MOVIE_ACTIVE_INFO, { id: id });
    };
    WidgetEventBroker.prototype.removeMovie = function (id) {
        return this.emit(MESSAGES.MOVIE_REMOVE, { id: id });
    };
    WidgetEventBroker.prototype.getMoviesList = function () {
        return this.emit(MESSAGES.MOVIES_LIST);
    };
    WidgetEventBroker.prototype.getLimitsInfo = function (site, excludeMovieId) {
        return this.search({
            site: site,
            excludeMovieId: excludeMovieId,
            count: 'y',
            since: Math.floor(Date.now() / 1000) - 86400,
        });
    };
    WidgetEventBroker.prototype.getGiftMovies = function (payload) {
        return this.emit(MESSAGES.GIFT_ADD, payload);
    };
    WidgetEventBroker.prototype.moveBackward = function () {
        this.emit(MESSAGES.MOVE_BACKWARD);
    };
    WidgetEventBroker.prototype.moveForward = function () {
        this.emit(MESSAGES.MOVE_FORWARD);
    };
    return WidgetEventBroker;
}(BaseWidgetEventBroker));
export default WidgetEventBroker;

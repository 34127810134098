var PUBLIC_METHODS = [
    'on',
    'search',
    'show',
    'hide',
    'loadMovie',
    'addMovie',
    'removeMovie',
    'getMoviesList',
    'getLimitsInfo',
    'getMovieInfo',
    'getGiftMovies',
    'moveBackward',
    'moveForward',
];
export default PUBLIC_METHODS;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { h, Component } from 'preact';
import { MESSAGES } from 'types/messages';
import styles, { CLASS_NAMES } from './styles';
import Analytics from '../Analytics';
import Blackout from '../Blackout';
import Iframe from '../Iframe';
import Scrollbar from '../Scrollbar';
import AppFooter from '../AppFooter';
var VISIBLE_Z_INDEX = Math.pow(2, 31);
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isFocused: true,
            isVisible: false,
        };
        return _this;
    }
    App.prototype.getVisibilityStyle = function () {
        var _a = this.state, isVisible = _a.isVisible, isFocused = _a.isFocused;
        var store = this.props.store;
        var _b = store.dataset, _c = _b.zMax, zMax = _c === void 0 ? VISIBLE_Z_INDEX : _c, _d = _b.zMin, zMin = _d === void 0 ? 0 : _d;
        if (isVisible || isFocused) {
            return {
                'z-index': isVisible ? VISIBLE_Z_INDEX : Number(zMax),
                'pointer-events': 'all',
            };
        }
        return {
            'z-index': Number(zMin),
            'pointer-events': 'none',
        };
    };
    App.prototype.componentDidMount = function () {
        var services = this.props.services;
        services.observer.attach(this);
        document.head.append(App.STYLES);
    };
    App.prototype.componentWillUnmount = function () {
        var _a = this.props, services = _a.services, store = _a.store;
        App.STYLES.remove();
        services.observer.detach(this);
        services.observer.detach(store);
        services.postmate.dispose();
        services.broker.dispose();
    };
    App.prototype.update = function (type) {
        switch (type) {
            case MESSAGES.MOVE_BACKWARD_RESPONSE:
                this.setState({ isFocused: false });
                break;
            case MESSAGES.MOVE_FORWARD_RESPONSE:
                this.setState({ isFocused: true });
                break;
            case MESSAGES.SHOW_RESPONSE:
            case MESSAGES.NOTIFICATION_OPEN_RESPONSE:
                this.setState({ isVisible: true });
                break;
            case MESSAGES.HIDE_RESPONSE:
                this.setState({ isVisible: false });
                break;
            default:
        }
    };
    App.prototype.render = function () {
        var _a = this.props, services = _a.services, store = _a.store;
        var deps = { services: services, store: store };
        return h('div', {
            className: CLASS_NAMES.APP,
            style: this.getVisibilityStyle(),
        }, h(Analytics, { services: services }), h(Blackout, { services: services }), h(Scrollbar, deps), h(Iframe, deps), h(AppFooter, deps));
    };
    App.STYLES = (function () {
        var node = document.createElement('style');
        node.innerHTML = styles;
        return node;
    })();
    return App;
}(Component));
export default App;

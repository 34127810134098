import { render as preactRender, h } from 'preact';
import App from './components/App';
export var create = function (services, className) {
    if (className === void 0) { className = 'v-rack'; }
    return function (store) {
        var root = document.createElement('div');
        root.classList.add(className);
        document.body.prepend(root);
        preactRender(h(App, { services: services, store: store }), root);
        return { root: root, services: services, store: store };
    };
};
export var destroy = function (app) {
    preactRender(null, app);
};

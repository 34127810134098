var _a, _b;
/* eslint-disable import/no-import-module-exports */
import 'promise-polyfill/src/polyfill';
import compose from 'utils/compose';
import getOptions from './options';
import { create as createStore } from './store';
import { create, destroy } from './app';
import PUBLIC_METHODS from './public-methods';
import Services from './services';
import PUBLIC_EVENTS from './public-events-map';
var app = null;
var services = new Services(PUBLIC_EVENTS);
var isAutorun = ((_b = (_a = document.currentScript) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b.autorun) !== 'false';
var createApp = compose(getOptions, createStore(services), create(services));
var boundMethods = PUBLIC_METHODS.reduce(function (acc, method) {
    var _a, _b;
    if (typeof services.broker[method] === 'function') {
        return Object.assign(acc, (_a = {},
            _a[method] = services.broker[method].bind(services.broker),
            _a));
    }
    return Object.assign(acc, (_b = {},
        _b[method] = function () {
            console.log("".concat(method, " has no implementation."));
        },
        _b));
}, {});
if (isAutorun) {
    app = createApp({});
}
export var startup = function (options) {
    app = createApp(options || {});
};
export var shutdown = function () {
    var _a = app || {}, store = _a.store, root = _a.root;
    services.observer.detach(store);
    services.postmate.dispose();
    destroy(root);
    root.remove();
};
export var restart = function (options) {
    shutdown();
    startup(options);
};
export var addMovie = boundMethods.addMovie, getGiftMovies = boundMethods.getGiftMovies, getLimitsInfo = boundMethods.getLimitsInfo, getMovieInfo = boundMethods.getMovieInfo, getMoviesList = boundMethods.getMoviesList, hide = boundMethods.hide, loadMovie = boundMethods.loadMovie, moveBackward = boundMethods.moveBackward, moveForward = boundMethods.moveForward, on = boundMethods.on, removeMovie = boundMethods.removeMovie, search = boundMethods.search, show = boundMethods.show;
// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept();
}
/* eslint-disable import/no-import-module-exports */

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { h, Component } from 'preact';
import { MESSAGES } from 'types/messages';
import { CLASS_NAMES, IFRAME_CLASS_NAME } from '../App/styles';
/* eslint-disable no-underscore-dangle */
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.timeStamp = 0;
        _this.pointerTimestamp = 0;
        _this.className = '';
        return _this;
    }
    Button.prototype.onHover = function (event) {
        var _a;
        var _b = this.props, store = _b.store, services = _b.services;
        var broker = services.broker, observer = services.observer;
        // There is an unexpected button layout shift which causes pointerenter event to fire.
        // This is happening when user closes widget. We can filter this case with relatedTarget
        // element help.
        var relatedTarget = event === null || event === void 0 ? void 0 : event.relatedTarget;
        if ((_a = relatedTarget === null || relatedTarget === void 0 ? void 0 : relatedTarget.classList) === null || _a === void 0 ? void 0 : _a.contains(IFRAME_CLASS_NAME)) {
            return;
        }
        if (!broker.connected) {
            return;
        }
        if (!store.isDisabled) {
            this.pointerTimestamp = event.timeStamp;
            observer.notify(MESSAGES.OVER);
        }
    };
    Button.prototype.onPointerOut = function (event) {
        var services = this.props.services;
        var observer = services.observer;
        var timestampDelta = event.timeStamp - this.pointerTimestamp;
        // Sometimes MESSAGES.OUT event isn't fired by widget.
        // This measure just helps to prevent holding the focus on the widget rather than on a website.
        if (timestampDelta < 100) {
            this.pointerTimestamp = 0;
            observer.notify(MESSAGES.OUT);
        }
    };
    Button.prototype._onClick = function (event) {
        if (Math.floor(event.timeStamp - this.timeStamp) < 300) {
            this.onClick(event);
        }
        this.timeStamp = event.timeStamp;
    };
    Button.prototype.componentDidMount = function () {
        this.props.services.observer.attach(this);
    };
    Button.prototype.componentWillUnmount = function () {
        this.props.services.observer.detach(this);
    };
    Button.prototype.render = function () {
        var store = this.props.store;
        var isVisible = this.state.isVisible;
        var isDisabled = store.isDisabled;
        var canClick = isVisible && !isDisabled;
        return h('button', {
            className: "".concat(this.className, " ").concat(CLASS_NAMES.BUTTON),
            onClick: this.onClick.bind(this),
            onTouchStart: this._onClick.bind(this),
            onTouchEnd: this._onClick.bind(this),
            onPointerEnter: this.onHover.bind(this),
            onPointerOut: this.onPointerOut.bind(this),
            style: { 'pointer-events': canClick ? 'all' : 'none' },
        });
    };
    return Button;
}(Component));
export default Button;
/* eslint-enable no-underscore-dangle */

export var GTM_PARAMETERS = {
    EVENT_NAME: 'event_name',
    ACTION: 'action',
    WEBSITE: 'org_site',
    LOCATION: 'action_location',
    WEBSITE_MOVIE_ID: 'movie_id',
    VRACK_ACTRESS: 'actress_name',
    VRACK_MOVIE_TITLE: 'movie_title',
    VRACK_MOVIE_ID: 'vrack_movie_id',
    VRACK_LOCATION: 'action_location',
    VRACK_MOVIE_SITE: 'movie_site',
    VRACK_MOVIE_CURRENT_TIME: 'movie_current_time',
    COLLECTION_TITLE: 'collection_name',
    THEME_CHANGE: 'theme',
    FULLSCREEN: 'window_size',
    VIEW_CHANGE: 'view_type',
    SORT_CHANGE: 'sort_type',
    LANGUAGE_CHANGE: 'lang',
    NOTIFICATION_TEXT: 'text',
    NOTIFICATION_COLOR: 'theme_color',
    CAMPAIGN_NAME: 'campaign_name',
    STORY_NUM: 'story_num',
    STORY_MOVIE_URL: 'movie_url',
    STORY_BUTTON_URL: 'button_url',
};

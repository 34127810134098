var BaseWidgetEventBroker = /** @class */ (function () {
    function BaseWidgetEventBroker(eventService) {
        this.eventService = eventService;
    }
    Object.defineProperty(BaseWidgetEventBroker.prototype, "connected", {
        get: function () {
            return this.eventService.connected;
        },
        enumerable: false,
        configurable: true
    });
    BaseWidgetEventBroker.prototype.setContext = function (context) {
        if (typeof context !== 'object') {
            throw new TypeError("Expected 'context' to be an object. Got - ".concat(context, "."));
        }
        if (typeof context.target !== 'object') {
            throw new TypeError("Expected 'context.target' to be an iframe window object.");
        }
        if (typeof context.origin !== 'string') {
            throw new TypeError("Expected 'context.origin' to be a window location origin string.");
        }
        this.eventService.connect(context);
        return this.eventService.handshake;
    };
    BaseWidgetEventBroker.prototype.on = function (types, listener) {
        var _this = this;
        if (Array.isArray(types)) {
            var disposers_1 = types.map(function (type) {
                return _this.eventService.on(type, listener);
            });
            return function () {
                disposers_1.forEach(function (dispose) { return dispose(); });
            };
        }
        return this.eventService.on(types, listener);
    };
    BaseWidgetEventBroker.prototype.emit = function (type, payload) {
        if (payload === void 0) { payload = {}; }
        return this.eventService.emit(type, payload);
    };
    BaseWidgetEventBroker.prototype.emitAndWait = function (type, payload) {
        return this.eventService.emitAndWait(type, payload);
    };
    BaseWidgetEventBroker.prototype.dispose = function () {
        this.eventService.dispose();
    };
    return BaseWidgetEventBroker;
}());
export default BaseWidgetEventBroker;

var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
import { MESSAGES } from 'types/messages';
var PUBLIC_EVENTS = [
    { type: (_a = {}, _a[MESSAGES.HANDSHAKE] = 'ready', _a) },
    { type: (_b = {}, _b[MESSAGES.SHUTDOWN_RESPONSE] = 'shutdown', _b) },
    { type: (_c = {}, _c[MESSAGES.SHOW_RESPONSE] = 'show', _c) },
    { type: (_d = {}, _d[MESSAGES.HIDE_RESPONSE] = 'hide', _d) },
    { type: (_e = {}, _e[MESSAGES.VIDEO_ERROR_RESPONSE] = 'video-error', _e) },
    {
        type: (_f = {}, _f[MESSAGES.MOVIE_ADD_RESPONSE] = 'movie-add', _f),
        permissions: {
            canSubscribe: true,
            canTriggerPromise: true,
        },
    },
    {
        type: (_g = {}, _g[MESSAGES.MOVIE_REMOVE_RESPONSE] = 'movie-remove', _g),
        permissions: {
            canSubscribe: true,
            canTriggerPromise: true,
        },
    },
    { type: (_h = {}, _h[MESSAGES.LOADED] = 'movie-loaded', _h) },
    {
        type: (_j = {}, _j[MESSAGES.MOVIES_SEARCH_RESPONSE] = 'search', _j),
        permissions: {
            canSubscribe: false,
            canTriggerPromise: true,
        },
    },
    {
        type: (_k = {}, _k[MESSAGES.MOVIE_ACTIVE_INFO_RESPONSE] = 'movie-active-info', _k),
    },
    {
        type: (_l = {}, _l[MESSAGES.MOVIES_LIST_RESPONSE] = 'movies-info', _l),
        permissions: {
            canSubscribe: true,
            canTriggerPromise: true,
        },
    },
    { type: (_m = {}, _m[MESSAGES.THEME_RESPONSE] = 'theme-change', _m) },
    { type: (_o = {}, _o[MESSAGES.LANGUAGE_RESPONSE] = 'language-change', _o) },
    { type: (_p = {}, _p[MESSAGES.GIFT_ADD_RESPONSE] = 'gift-add', _p) },
    { type: (_q = {}, _q[MESSAGES.MOVE_BACKWARD_RESPONSE] = 'backward', _q) },
    { type: (_r = {}, _r[MESSAGES.MOVE_FORWARD_RESPONSE] = 'forward', _r) },
    { type: (_s = {}, _s[MESSAGES.VIDEO_PLAY_START_RESPONSE] = 'video-play-start', _s) },
    { type: (_t = {}, _t[MESSAGES.VIDEO_PLAY_RESUME_RESPONSE] = 'video-play-resume', _t) },
    { type: (_u = {}, _u[MESSAGES.VIDEO_PAUSE_RESPONSE] = 'video-pause', _u) },
    { type: (_v = {}, _v[MESSAGES.VIDEO_SEEK_RESPONSE] = 'video-seek', _v) },
    { type: (_w = {}, _w[MESSAGES.AUTH_REQUEST_RESPONSE] = 'auth-request', _w) },
];
export default PUBLIC_EVENTS;

var CACHE;
var getScrollBarWidth = function () {
    if (document === undefined) {
        return 0;
    }
    if (CACHE !== undefined) {
        return CACHE;
    }
    var element = document.createElement('div');
    element.style.display = 'block';
    element.style.position = 'absolute';
    element.style.top = '-999px';
    element.style.left = '-999px';
    element.style.width = '100px';
    element.style.height = '100px';
    element.style.overflow = 'scroll';
    document.body.insertBefore(element, null);
    CACHE = 100 - element.clientWidth;
    document.body.removeChild(element);
    return CACHE;
};
export default getScrollBarWidth;

import { MESSAGES } from 'types/messages';
import getScrollbarWidth from '../utils/get-scrollbar-width';
var LG_SIZE = 960;
/* eslint-disable no-underscore-dangle, no-restricted-syntax */
var StoreProvider = /** @class */ (function () {
    function StoreProvider(dataset) {
        this.dataset = null;
        this.isDisabled = false;
        this.isMobileScreen = window.innerWidth <= LG_SIZE;
        this._scrollbarWidth = getScrollbarWidth();
        this.dataset = dataset;
    }
    StoreProvider.prototype.update = function (type) {
        switch (type) {
            case MESSAGES.LOADING:
            case MESSAGES.MOVE_BACKWARD_RESPONSE:
                this.isDisabled = true;
                break;
            case MESSAGES.SHOW_RESPONSE:
            case MESSAGES.MOVE_FORWARD_RESPONSE:
            case MESSAGES.NOTIFICATION_OPEN_RESPONSE:
                this.isDisabled = false;
                break;
            default:
        }
    };
    Object.defineProperty(StoreProvider.prototype, "scrollbarWidth", {
        get: function () {
            if (this.isMobileScreen) {
                return "0px";
            }
            return "".concat(this._scrollbarWidth, "px");
        },
        enumerable: false,
        configurable: true
    });
    return StoreProvider;
}());
export default StoreProvider;
/* eslint-enable no-underscore-dangle, no-restricted-syntax */

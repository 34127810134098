import deepMerge from './utils/merge';
var host = window.location.host;
var DEFAULT_OPTIONS = {
    isoLang: host.indexOf('en.') > -1 ? 'en' : 'ja',
    promotion: 'V-RACKが一本道にもリリースされました。是非ご利用下さい。',
    promotionEn: 'V-RACK is now live on a new website 1pondo.tv! Check it out!',
    promotionUrl: 'https://www.1pondo.tv/',
    promotionChangeInterval: '5000',
    theme: 'light',
    xNetiPath: '/d2ptb',
    xNetiDomain: host,
    site: 'heydouga',
};
var CACHE = {
    OPTIONS: null,
};
var getOptions = function (options, defaultValues) {
    var _a;
    if (defaultValues === void 0) { defaultValues = DEFAULT_OPTIONS; }
    var withCache = (_a = CACHE.OPTIONS) !== null && _a !== void 0 ? _a : defaultValues;
    var withOptions = deepMerge(withCache, options);
    CACHE.OPTIONS = deepMerge(CACHE.OPTIONS || {}, withOptions);
    return CACHE.OPTIONS;
};
export default getOptions;

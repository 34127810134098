var ObserverService = /** @class */ (function () {
    function ObserverService() {
        this.observers = [];
    }
    ObserverService.prototype.push = function (observer) {
        if (!this.observers.includes(observer)) {
            this.observers.push(observer);
        }
    };
    ObserverService.prototype.attach = function () {
        var observers = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            observers[_i] = arguments[_i];
        }
        observers.forEach(this.push.bind(this));
    };
    ObserverService.prototype.detach = function (observer) {
        if (this.observers.indexOf(observer) > -1) {
            this.observers = this.observers.filter(function (o) { return o !== observer; });
        }
    };
    ObserverService.prototype.notify = function (type, data) {
        this.observers.forEach(function (o) { return o.update(type, data); });
    };
    Object.defineProperty(ObserverService.prototype, "length", {
        get: function () {
            return this.observers.length;
        },
        enumerable: false,
        configurable: true
    });
    return ObserverService;
}());
export default ObserverService;

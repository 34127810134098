var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable class-methods-use-this, no-undef */
import { h, Component } from 'preact';
import ButtonLauncher from '../ButtonLauncher';
import { CLASS_NAMES } from '../App/styles';
import ButtonNotifier from '../ButtonNotifier';
var AppFooter = /** @class */ (function (_super) {
    __extends(AppFooter, _super);
    function AppFooter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppFooter.prototype.render = function () {
        var _a = this.props, services = _a.services, store = _a.store;
        return h('div', {
            className: CLASS_NAMES.BUTTONS_GROUP,
        }, [
            h(ButtonNotifier, {
                store: store,
                services: services,
            }),
            h(ButtonLauncher, { store: store, services: services }),
        ]);
    };
    return AppFooter;
}(Component));
export default AppFooter;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component } from 'preact';
import { MESSAGES } from 'types/messages';
var Scrollbar = /** @class */ (function (_super) {
    __extends(Scrollbar, _super);
    function Scrollbar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isVisible: true,
            wasVisible: true,
        };
        return _this;
    }
    Scrollbar.prototype.componentDidMount = function () {
        this.props.services.observer.attach(this);
    };
    Scrollbar.prototype.componentWillUnmount = function () {
        this.props.services.observer.detach(this);
    };
    Scrollbar.prototype.update = function (type) {
        switch (type) {
            case MESSAGES.MOVE_FORWARD_RESPONSE:
                this.setState(function (prev) { return ({
                    isVisible: prev.wasVisible,
                    wasVisible: null,
                }); });
                break;
            case MESSAGES.MOVE_BACKWARD_RESPONSE:
                this.setState(function (prev) { return ({
                    isVisible: true,
                    wasVisible: prev.isVisible,
                }); });
                break;
            case MESSAGES.HIDE_RESPONSE:
                this.setState({ isVisible: true });
                break;
            case MESSAGES.SHOW_RESPONSE:
            case MESSAGES.NOTIFICATION_OPEN_RESPONSE:
                this.setState({ isVisible: false });
                break;
            default:
        }
    };
    Scrollbar.prototype.render = function () {
        var store = this.props.store;
        var isVisible = this.state.isVisible;
        document.body.style.paddingRight = isVisible ? null : store.scrollbarWidth;
        document.body.style.overflow = isVisible ? null : 'hidden';
        return null;
    };
    return Scrollbar;
}(Component));
export default Scrollbar;
